<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Quản lý vai trò</h6>
          <b-row style="float: right">
            <b-button variant="outline-primary" href="#/create-role"
              >Thêm mới vai trò</b-button
            >
            &nbsp;
            <b-button variant="outline-primary" href="#/permission"
              >Quản lý phân quyền</b-button
            >
          </b-row>
        </template>
        <b-table
          table-class="datatable-table"
          hover
          tbody-tr-class="datatable-row"
          thead-tr-class="datatable-row"
          thead-class="datatable-head"
          details-td-class="datatable-cell"
          :items="items"
          :fields="fields"
        >
          <template #cell(options)="data">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="linkDetail(data.item.id)"
              size="sm"
              >Chỉnh sửa
            </b-button>
            &nbsp;
            <b-button
              class="mr-2"
              variant="outline-danger"
              @click="deleteItem(data.item.id)"
              size="sm"
              >Xoá
            </b-button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="view.currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="view.paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  components: {},
  mixins: [Common],
  data() {
    return {
      items: [],
      fields: [{ id: "Id" }, { name: "Vai trò" }, { options: "Tùy chọn" }],
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý vai trò và phân quyền", route: "role-permission" },
    ]);
  },
  methods: {
    listAll() {
      let params = {
        page: this.view.currentPage,
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.getAllRoles(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.items = response.data.data.data;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage = response.data.data.last_page;
            this.view.currentPage = response.data.data.current_page;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    linkDetail(id) {
      this.$router.push("/detail-role?id=" + id);
      // return this.$router.push({ name: "fee-view", params: { id: id } });
    },
    deleteItem(id) {
      this.$bus.$emit("show-loading", true);
      CmsRepository.deleteRoles(id)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.listAll();
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
  },
  created() {
    this.listAll();
  },
  watch: {
    "view.currentPage"() {
      this.listAll();
    },
  },
};
</script>
